<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import CompanyDescriptionInput from '@components/company-description/CompanyDescriptionInput.vue'
import { type personaPayload } from '../stores/persona'
import { useRouter } from 'vue-router'
import PricingComponent from '@/components/pricing/PricingComponent.vue'
import ExampleComponent from '@/components/example/ExampleComponent.vue'
import LayoutComponent from '@/components/layout-section/LayoutSection.vue'
import BenefitsComponent from '@/components/benefits/BenefitsComponent.vue'
import LoginComponent from '../components/auth/login/LoginComponent.vue'
import RegisterComponent from '../components/auth/RegisterComponent.vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '../stores/auth'
import { getHomePage, getPainPoints } from '../services/strapi-service'
import { useHead } from '@vueuse/head'
import { constructHead } from '../utils/constructHead'

const router = useRouter()
const isLoading = ref(false)
const store = useAuthStore()
const { showLogin, showRegister, isLoggedIn } = storeToRefs(useAuthStore())
const authStore = useAuthStore()

const handleSubmit = async ({ summary }: personaPayload) => {
  // check if user is logged in
  if (!isLoggedIn.value) {
    authStore.toggleRegister(true)
    localStorage.setItem('summary', summary)
    return
  }

  try {
    router.push({ name: 'target-audience', query: { summary } })
  } catch (error) {
    isLoading.value = false
  }
}

const items = ref([])

onBeforeMount(async () => {
  const data = await getHomePage()

  if (data.seo) {
    useHead(computed(() => constructHead(data.seo!)))
  }
})

onMounted(async () => {
  store.toggleLogin(false, false)
  items.value = await getPainPoints()
})
</script>

<template>
  <main>
    <div v-if="!showLogin && !showRegister">
      <header>
        <div class="header-left">
          <h1>Use AI to build a website blueprint</h1>
          <p class="lead-text">
            Standing out online is tough, whether you're building a site for yourself or someone else. Answer three
            simple questions, and Laiya's AI will create a custom plan for success.
          </p>
        </div>

        <div class="try-it">
          <div class="try-it-title">
            <h2>Try it for yourself</h2>
            <p class="lead-text">Tell us about your organisation in a few words</p>
          </div>
          <div class="company-input">
            <CompanyDescriptionInput :showName="false" section="HOME" @submitted="handleSubmit" />
          </div>
        </div>
      </header>
    </div>
    <div class="login-wrapper">
      <login-component v-show="showLogin" />
      <register-component v-show="showRegister" :navigate-user="false" :is-home="true" />
    </div>

    <section class="">
      <h2>What will you get?</h2>
      <p class="lead-text">Discover the actionable insights we provide in seconds.</p>
    </section>
    <LayoutComponent />

    <section class="margin full-width video">
      <div class="body">
        <h2>How it works: Watch the demo</h2>
        <div class="demo">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/of9EykjgaKA?si=YWNshb4MLKP3NP3I"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>

    <BenefitsComponent />

    <section class="padding">
      <PricingComponent />
    </section>
    <ExampleComponent />
    <div class="try-it-bottom full-width">
      <div class="try-it-title">
        <h2>Try it for yourself</h2>
        <p class="lead-text">Tell us about your organisation in a few words</p>
      </div>
      <div class="company-input">
        <CompanyDescriptionInput :showName="false" section="HOME" @submitted="handleSubmit" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

h1 {
  margin-bottom: 0.5rem;
}

h2 {
  margin-bottom: 0.5rem;
}

.margin {
  padding: 60px;
}

.padding {
  padding: 60px 0;
}

.video {
  background-color: $grey-2;
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 2.5rem;
  @include laptop {
    margin-bottom: 3.75rem;
  }
}

header {
  padding-top: 44px;
  padding-bottom: 60px;

  .header-left {
    flex: 1;
    margin-bottom: 2.5rem;

    h1 {
      margin-bottom: 1.5rem;
      @include laptop {
        margin-bottom: 2rem;
      }
    }
  }

  @include laptop {
    display: flex;
    justify-content: space-between;
    gap: 9.125rem;
  }

  .try-it {
    .try-it-title {
      background-color: $dark-green;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 1.5rem;
      @include laptop {
        padding: 3rem;
      }
    }

    .company-input {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      background-color: $mid-green;
      position: relative;
      z-index: 2;
      padding: 1.5rem;
      @include laptop {
        padding: 3rem;
      }

      :deep(input) {
        background-color: $light-green;
      }
    }

    .cube {
      display: none;

      @include laptop {
        display: block;
        position: absolute;
        right: 50%;
        bottom: -61px;
        width: 104px;
        height: auto;
        z-index: 1;
      }

      @include laptop {
        right: 50%;
      }

      @include xxl {
        right: 50% px;
        bottom: -48px;
        width: 109px;
      }
    }

    margin-top: 1rem;
    flex: 1;
    position: relative;
  }
}

.what-you-get-section {
  @include laptop {
    margin-bottom: 6.875rem;
  }
  margin-bottom: 3.75rem;
  @include laptop {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 9.125rem;
  }

  .what-you-get {
    margin-top: 3rem;
    flex: 1;

    h2 {
      margin-bottom: 0.75rem;
    }

    .items {
      margin-top: 3.75rem;

      .item {
        display: flex;
        border-radius: 30px;
        margin-bottom: 1rem;
        overflow: hidden;
        background-color: $dark-grey;
        align-items: center;

        .icon {
          padding: 1.5rem 1.75rem;
          @include laptop {
            padding: 2.5rem;
          }
          height: 100%;

          img {
            height: 73px;
            width: 73px;
            display: block;
          }
        }

        .item-content {
          padding: 1.75rem;
          @include laptop {
            padding: 2.5rem 3rem;
          }
          background-color: $mid-grey;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .computer-items {
    display: none;
    @include laptop {
      display: block;
      flex: 1;
      position: relative;
      .computer-image {
        display: block;
        height: auto;
        margin: 0 auto;
        width: 419px;
      }

      .cubes {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -17rem;
        right: 50;
        width: 459px;
        height: auto;
        z-index: 3;
      }
    }

    @include xxl {
      .computer-image {
        width: 468px;
      }

      .cubes {
        width: 562px;
        top: -23rem;
      }
    }
    @include xxxl {
      .cubes {
        width: 498px;
        top: -19rem;
      }
    }

    @include laptop {
      .computer-image {
        width: 549px;
      }
      .cubes {
        width: 493px;
        top: -18rem;
      }
    }
  }
}

.demo {
  border-radius: 30px;
  background-color: $mid-cyan;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  @include laptop {
    height: 830px;
    margin-top: 3rem;
  }

  img {
    @include laptop {
      width: 130px;
      height: 130px;
    }
  }
}

.try-it-bottom {
  display: flex;
  flex-direction: column;

  .try-it-title {
    background-color: $dark-green;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .company-input {
    background-color: $mid-green;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      width: 100%;
      @include md {
        width: 40%;
        min-width: 500px;
      }

      :deep(input) {
        background-color: $light-green;
      }

      :deep(button) {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.login-wrapper {
  margin-top: 44px;
}
</style>
